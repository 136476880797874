<template>
  <v-card
    style="height: 100%"
    class=""
  >
    <v-card-title class="text-capitalize">{{ $t("Publications médias") }}
    <v-spacer />
      <v-btn
        icon
        small
        color="primary"
      >
        <v-icon
          color="primary"
          @click="openDialog({})"
        >
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="text--primary overflow-y-auto" style="max-height: 250px">
      <p v-if="!(author.author_media_publications || []).length">{{ $t("Aucun publications médias renseigné") }}</p>
      <div v-for="it in author.author_media_publications" :key="it.id">
        <div
          class="d-flex justify-space-between align-center"
        >
          <div>
            <div class="bold">
              <div>{{it.media}}</div>
              <div>{{ it.media_type?it.media_type.label:'-' }}</div>
            </div>
            <div class="body-2">
              <span class="mr-3">{{ formatDate(it.date) }}</span>
            </div>
            <div class="body-2 d-flex flex justify-space-between">

              <span class="">Lieu : {{ it.location || '' }}</span>

            </div>
          </div>
          <div class="text--primary font-weight-semibold ml-2">


            <v-btn
              v-if="it.link && it.link.length > 2"
              :href="it.link" target="_blank"
              icon
              x-small
              color="success"
            >
              <v-icon
                small
                color="success"
              >
                {{ icons.mdiLink }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              x-small
              color="info"
              @click="openDialog(it)"
            >
              <v-icon
                small
                color="info"

              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              x-small
              color="error"
              @click="openDeleteDialog(it)"
            >
              <v-icon
                small
                color="error"

              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider />
      </div>
    </v-card-text>
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 400 : '100%'"
    >
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onSubmit"
      >
        <v-card
          height="100%"
          flat
        >
          <v-card-title>
            <span class="font-weight-semibold text-base text--primary">{{ item && item.id?$t('Modifier'):$t("Ajouter une publication média") }}</span>
            <v-spacer />
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              color="error"
              @click="isFormActive = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text
            class="overflow-y-auto"
            style="max-height: 700px;"
          >
            <v-row>

              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="item.author_media_type_id"
                  :label="$t('Type de média')"
                  :items="$store.state.app.mediaTypes"
                  required
                  item-text="label"
                  item-value="id"
                  outlined
                  dense
                  hide-details="auto"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="item.media"
                  outlined
                  dense
                  :label="$t('Nom du média')"
                  :placeholder="$t('Nom du média')"
                  :error-messages="errorMessages.media"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="item.link"
                  outlined
                  dense
                  :label="$t('Lien de l’article, vidéo ou podcast')"
                  :placeholder="$t('Lien de l’article, vidéo ou podcast')"
                  :error-messages="errorMessages.link"
                  hide-details="auto"
                  :prepend-inner-icon="icons.mdiLink"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <date-picker
                  v-model="item.date"
                  outlined
                  dense
                  :label="$t('Date de parution')"
                  :placeholder="$t('Date de parution')"
                  :error-messages="errorMessages.date"
                  hide-details="auto"
                ></date-picker>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="item.location"
                  outlined
                  dense
                  :label="$t('Lieu de parution')"
                  :placeholder="$t('Lieu de parution')"
                  :error-messages="errorMessages.location"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
<!--              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="item.link"
                  outlined
                  dense
                  :label="$t('Lien vers le compte')"
                  :placeholder="$t('Lien vers le compte')"
                  :error-messages="errorMessages.link"
                  hide-details="auto"
                ></v-text-field>
              </v-col>-->
<!--              <v-col
                cols="12"
                sm="6"
              >
                <v-switch
                  v-model="item.status"
                  :label="$t('État de la communauté')"
                  :true-value="1"
                  :false-value="0"
                ></v-switch>
              </v-col>-->
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="resetItem"
            >
              {{ $t('Annuler') }}
            </v-btn>
            <v-spacer />
            <v-btn
              :loading="loading"
              color="primary"
              class="me-3"
              type="submit"
            >
              {{ $t("Enregistrer") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="isDeleteFormActive"
      touchless
      :right="!$vuetify.rtl"
      width="400"
    >
      <v-card
        height="100%"
        flat
      >
        <v-card-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t("Confirmer la suppression") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            color="error"
            @click="isDeleteFormActive = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text
          class="overflow-y-auto"
          style="max-height: 700px;"
        >
          <p class="text-center">
            {{ $t("Êtes-vous sûr de vouloir supprimer cet élément ?") }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="deleting"
            color="secondary"
            outlined
            type="reset"
            @click="isDeleteFormActive = false"
          >
            {{ $t('Annuler') }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="deleting"
            color="primary"
            class="me-3"
            @click="deleteItem"
          >
            {{ $t("Continue") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiDeleteOutline, mdiPlus, mdiLink
} from '@mdi/js'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import { required, emailValidator, urlValidator } from '@core/utils/validation'
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import DatePicker from "@/components/DatePicker.vue";

/* import useAuthorsList from './useAuthorsList'
import FileUpload from '@/components/file_upload'
import Addresses from '@/views/apps/author/author-list/Addresses'
import authorStoreModule from '@/views/apps/author/authorStoreModule'
import AuthorForm from "@/views/apps/author/author-list/AuthorForm.vue"; */

export default {
  components: {
    DatePicker
  },
  model: {
    prop: 'object',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
  },
  methods: { formatDate },
  setup(props, { emit }) {
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const deleting = ref(false)
    const item = ref({})
    const isFormActive = ref(false)
    const isDeleteFormActive = ref(false)
    const form = ref(null)
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const validate = () => {
      form.value.validate()
    }

    const author = computed({
      get: () => props.object,
      set: value => emit('object', value),
    })
    author.value.author_media_publications = author.value.author_media_publications || []

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}
      // resetForm()

      isFormActive.value = false
    }
    const deleteItem = () => {
      if (!item.value.id) author.value.author_media_publications.splice(author.value.author_media_publications.findIndex(e => e.id === item.value.id), 1)
      deleting.value = true
      axios.delete(`/author/${author.value.id}/publications/${item.value.id}`).then(response => {
        isDeleteFormActive.value = false
        deleting.value = false
        emit('object', response.data)
       // author.value = response.data
       emit('refresh-data')


      }).then(() => {
        isDeleteFormActive.value = false
        deleting.value = false
      })
    }
    const openDialog = it => {
      item.value = it || {}

      isFormActive.value = true
    }
    const openDeleteDialog = it => {
      item.value = it || {}
      deleting.value = false
      isDeleteFormActive.value = true
    }

    const onSubmit = () => {
      if (!item.value.author_media_type_id) {
        isSnackbarBottomVisible.value = true
        snackBarMessage.value = 'Veuillez selectioner un type de média.'

        return
      }
      if (item.value.link && urlValidator(item.value.link) !== true) {
        isSnackbarBottomVisible.value = true
        snackBarMessage.value = 'Veuillez saisir un lien valide.'
        return
      }
      if (valid.value) {
        loading.value = true
        console.log('saving publication', item.value)
        axios.post(`author/${author.value.id}/publications`, item.value)
          .then(response => {
            loading.value = false
            isFormActive.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }

            // resetItem()
            emit('object', response.data)
            //author.value = response.data
            emit('refresh-data')

            // emit('update:is-form-active', false)
          }).catch(() => {
            loading.value = false
            isFormActive.value = false
          }).catch(error => {
            loading.value = false
            isFormActive.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    /* store
      .dispatch('app/fetchNetworks', { per_page: 500 }) */

    return {
      openDialog,
      openDeleteDialog,
      resetItem,
      deleteItem,
      form,
      loading,
      deleting,
      isFormActive,
      isDeleteFormActive,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      countries,
      item,
      author,
      valid,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiDeleteOutline,
        mdiLink,
      },
    }
  },
}
</script>
